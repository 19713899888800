import { Link } from "gatsby"
import React from "react"

function SurveyArticles({ template }) {
  return (
    <article className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-lg overflow-hidden shadow-lg ">
      <div>
        <img className="w-10 h-10" src={template.icon} alt={template.title} />
        <h2 className="box-title font-bold my-3 clamp-2">{template.title}</h2>
        <p className="box-subtitle line-clamp-3 mb-3">{template.excerpt}</p>
        <Link to={`${template.uri}`}>
          <button className="border px-4 py-2 rounded-2xl border-orange transform hover:-translate-y-1  duration-500 ease-in-out">
            <p className="text-orange text-sm m-0">Try for free</p>
          </button>
        </Link>
      </div>
    </article>
  )
}

export default SurveyArticles
